<template>
    <section v-if="!_.isEmpty(carpeta)">
        <div class="row mx-0 align-items-center bg-white pt-3 br-t-12">
            <div class="col-auto text-general f-600 f-18">
                Campañas
            </div>
            <el-tooltip placement="bottom" content="Eliminar Carpeta" effect="light">
                <button class="btn-action border ml-auto" @click="borrarCarpeta()">
                    <i class="icon-delete-outline text-general f-18" />
                </button>
            </el-tooltip>
            <el-tooltip placement="bottom" content="Cambiar nombre carpeta" effect="light">
                <button class="btn-action ml-2 border" @click="editarCarpeta()">
                    <i class="icon-pencil-outline text-general f-18" />
                </button>
            </el-tooltip>
            <el-tooltip placement="bottom" content="Crear campaña" effect="light">
                <button class="btn-red ml-2" @click="crearCarpeta()">
                    <i class="icon-plus text-white f-15" />
                </button>
            </el-tooltip>
            <div class="col-3">
                <el-input class="w-100 br-20" v-model="buscar" placeholder="Buscar campaña" size="small" />
            </div>
        </div>
        <div class="row mx-0">
            <div class="col-12 px-0">
                <tabla-general :data="campanasFiltered" :columnas="dataColumnas" :mostrar-buscador="false" alto="calc(100vh - 341px)" :usar-paginacion="false" class-header="text-general f-17 f-600" activar-seleccion @seleccion="ver($event)">
                    <template slot="adicionales-izquierda">
                        <el-table-column
                        width="50"
                        class-name="text-center"
                        >
                            <template slot-scope="{row}">
                                <i
                                :class="`${row.destacado ? 'icon-star text-warning' : 'icon-star-empty text-gris'}`"
                                />
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <!-- Partials -->
        <modal-eliminar ref="modalEliminarCarpeta" titulo="Eliminar Carpeta" no-aceptar @eliminar="eliminarCarpeta()">
            <p class="text-general f-15 text-center mb-4">
                ¿Desea eliminar esta carpeta? <br /> Perderá todas las campañas
            </p>
        </modal-eliminar>
        <modal ref="modalAnadirCarpeta" icon="carpeta_nueva" titulo="Crear Carpeta" adicional="Guardar" no-aceptar @adicional="actualizarCarpeta()">
            <div class="row mx-0 my-4 justify-center">
                <div class="col-10">
                    <p class="text-general f-12 pl-3">Nombre de la carpeta</p>
                    <el-input v-model="nombre" class="w-100" />
                </div>
            </div>
        </modal>
        <modal-crear-campana ref="modalCrearCampana" @saved="listarCarpetas();listarCampanas()" />
    </section>
</template>

<script>
import notificaciones from '~/services/publicidad/notificaciones'
import { mapActions, mapGetters, mapMutations } from  'vuex'
export default {
    components: {
        modalCrearCampana: () => import('./partials/modalCrearCampana')
    },
    data(){
        return {
            nombre: '',
            buscar: '',
            dataTable: [
                {
                    nombre: 'Mensajes de bienvenida',
                    Lanzamientos: 15,
                    destinatario: '1542',
                    apertura: '100%'
                }
            ],
            dataColumnas: [
                {valor: 'nombre', titulo: 'Nombre'},
                {valor: 'lanzamientos', titulo: 'Lanzamientos'},
                {valor: 'total_destinatarios', titulo: 'Destinatarios'},
                {valor: 'total_apertura', titulo: 'Apertura'}
            ],
        }
    },
    computed: {
        ...mapGetters({
            carpeta: 'publicidad/notificaciones/carpeta',
            campanas: 'publicidad/notificaciones/campanas',
        }),
        campanasFiltered(){
            return this.campanas.filter(el => !this.buscar || el.nombre.toLowerCase().includes(this.buscar.toLowerCase()))
        }
    },
    watch:{
        carpeta:{
            deep: true,
            handler(){
                this.listarCampanas()
            }
        }
    },
    methods: {
        ...mapActions({
            listarCarpetas: 'publicidad/notificaciones/listarCarpetas',
            listarCampanas: 'publicidad/notificaciones/listarCampanas',
        }),
        ...mapMutations({
            setCarpeta: 'publicidad/notificaciones/setCarpeta',
        }),
        borrarCarpeta(){
            this.$refs.modalEliminarCarpeta.toggle();
        },
        editarCarpeta(){
            this.nombre = this.carpeta.nombre
            this.$refs.modalAnadirCarpeta.toggle();
        },
        crearCarpeta(){
            this.$refs.modalCrearCampana.toggle();
        },
        async eliminarCarpeta(){
            try {
                const { data } = await notificaciones.eliminarCarpeta(this.carpeta.id);
                if (data.exito){
                    this.$refs.modalEliminarCarpeta.toggle();
                    this.setCarpeta({})
                    await this.listarCarpetas()

                }
            } catch (error){
                this.error_catch(error)
            }
        },
        async actualizarCarpeta(){
            try {
                let model = {
                    nombre: this.nombre
                }
                const { data } = await notificaciones.actualizarCarpeta(model, this.carpeta.id);
                if(data.exito){
                    this.notificacion('', 'Actualizado correctamente', 'success')
                    await this.listarCarpetas()
                    this.setCarpeta(data.carpeta)
                    this.$refs.modalAnadirCarpeta.toggle();

                }
            } catch (error){
                this.error_catch(error)
            }
        },
        ver(item){
            this.$router.push({ name: 'admin.publicidad.mensaje-notificacion.campana', params: { id_campana: item.id } })
        }
    }
}
</script>
<style lang="scss" scoped>
.btn-notificaciones{
    width: 32px;
    height: 32px;
    background-image: linear-gradient(to bottom, #FFFFFF, #F3F5F7);
}
.btn-agregar{
    width: 32px;
    height: 32px;
    background-color: #FF1720;
}
</style>
